<template>
  <div class="done">
    <mt-header :title="title">
      <router-link to="/" slot="left">
        <mt-button icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="title">
      <!-- 审批中 -->
      <template v-if="apply.approveResult == 'WAIT'">
        <svg aria-hidden="true" class="icon">
          <use xlink:href="#iconshenhezhong" />
        </svg>
        <div>{{$t('waitTips1')}}</div>
        <div class="refresh" @click="init">{{$t('waitTips2')}}</div>
      </template>
      <!-- 资料待修改 -->
      <template v-if="apply.approveResult == 'SUPPLEMENTARY'">
        <svg aria-hidden="true" class="icon">
          <use xlink:href="#iconweb-icon-" />
        </svg>
        <div>{{$t('supplementaryTips1')}}</div>
        <div>{{$t('supplementaryTips2')}}</div>
        <div>{{$t('supplementaryTips3')}}</div>
      </template>
      <!-- 审批通过 -->
      <template v-if="apply.approveResult == 'PASSED'">
        <svg aria-hidden="true" class="icon">
          <use xlink:href="#iconqianshoushenpitongguo-xianxing" />
        </svg>
        <div>{{$t('passedTips1')}}</div>
      </template>
      <!-- 审批拒绝 -->
      <template v-if="apply.approveResult == 'REJECTED'">
        <svg aria-hidden="true" class="icon">
          <use xlink:href="#iconjujue" />
        </svg>
        <div>{{$t('rejectedTips1')}}</div>
        <div>{{$t('rejectedTips2')}}</div>
      </template>
    </div>
    <div class="content">
      <mt-cell :title="$t('loanDate')" :value="apply.applyTime | formatDate('DD-MM-YYYY')"></mt-cell>
      <mt-cell :title="$t('loanAmount')" :value="apply.applyAmount | formatMoney"></mt-cell>
      <mt-cell :title="$t('repaymentPeriod')" :value="`${apply.displayLoanDays}${$t('day')}`"></mt-cell>
      <mt-cell :title="$t('applicationStatus')" :value="apply.approveResultName"></mt-cell>
    </div>
    <!--等待审批或审批通过-->
    <div class="msg" v-if="apply.approveResult == 'WAIT' || apply.approveResult == 'PASSED'">
      <svg aria-hidden="true" class="icon">
        <use xlink:href="#iconwarn" />
      </svg>
      <div v-if="apply.approveResult=='WAIT'" >{{$t('waitMsg1')}}</div>
      <div v-if="apply.approveResult=='PASSED'" >{{$t('passedMsg1')}}</div>
      <div v-if="apply.approveResult=='PASSED'" >{{$t('passedMsg1')}}</div>
    </div>
    <div class="footer">
      <!-- 资料待修改 -->
      <!-- <template v-if="apply.approveResult=='SUPPLEMENTARY'">
        <mt-button type="primary" size="large" plain @click="$router.push('/step/supplementary')">{{
          $t("modifyImmediately")
        }}</mt-button>
      </template> -->
      <!-- 审批拒绝或撤销 -->
      <template v-if="apply.approveResult=='REJECTED'||apply.applyStatus=='CANCEL'">
        <mt-button type="primary" size="large" @click="$router.push('/')">{{
          $t("reapply")
        }}</mt-button>
      </template>
    </div>
    <get-code ref="getCode" type="CONFIRM" @submit="onKaptcha" />
  </div>
</template>

<script>
import qs from "qs";
import { mapState, mapMutations } from "vuex";
import { apply, getUserInfo, kaptcha } from '../../utils/api'
import callPhone from '@/components/callPhone.vue'
import getCode from '@/components/getCode.vue'
import gtmPageView from '../../utils/gtm.js'
export default {
  name: "Done",
  components: {
    callPhone,
    getCode,
  },
  data() {
    return {
      apply: {},
    }
  },
  created() {
    gtmPageView(this)
    this.init()
  },
  beforeRouteLeave (to, from, next) {
    if (to.path == '/step/confirm') {
      next('/')
    } else {
      next()
    }
  },
  computed: {
    ...mapState(["user"]),
    title() {
      if (this.apply.approveResult == 'WAIT') {
        return this.$t('waitTips1')
      } else if (this.apply.approveResult == 'SUPPLEMENTARY') {
        return this.$t('supplementaryTips1')
      } else if (this.apply.approveResult == 'PASSED') {
        return this.t('passedTips1')
      } else if (this.apply.approveResult == 'REJECTED') {
        return this.$t('rejectedTips1')
      }
      return ''
    }
  },
  watch: {
    // 通过验证码验证是否是本人
    'user.userAuthStatus': {
      handler(val) {
        if (val == 'N' && this.user.deductionAuth == 'Y') {
          this.$nextTick(() => {
            this.$refs.getCode.popup(true)
          })
        }
      },
      immediate: true,
    },
    // 代扣
    'user.deductionAuth': {
      handler(val) {
        if (val == 'N') {
          this.$nextTick(() => {
            this.$router.push('/apply/deductionAuth')
          })
        }
      },
      immediate: true,
    }
  },
  methods: {
    ...mapMutations(["SET_CHECK_CODE", "SET_USER"]),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await Promise.all([
        this.getUser(),
        this.getApplyInfo(),
      ])
      this.$indicator.close();
    },
    // 校验手机验证码
    async onKaptcha(obj) {
      console.log(kaptcha, obj)
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.$axios({
        method: "post",
        url: kaptcha,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          phone: this.user.phone,
          type: 'CONFIRM',
          kaptcha: obj.validateCode,
          skipValidate: obj.isValidateCode == 'N'? 'Y': 'N',
        }),
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.$refs.getCode.popup(false)
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
    },
    async getUser() {
      await this.$axios({
        method: "get",
        url: getUserInfo,
      }).then((e) => {
        if (e.status.code == "000") {
          this.SET_USER(e.body);
        }
      }).catch(() => {})
    },
    async getApplyInfo() {
      await this.$axios({
        method: "get",
        url: `${apply}${this.user.applyId}`,
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.apply = e.body.apply
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
    },
  }
}
</script>

<style lang="scss" scoped>
.done {
  .title {
    text-align: center;
    padding: 10px;
    div {
      &:nth-last-child(2) {
        margin: 10px 0 5px 0;
        font-size: 18px;
        text-align: center;
        color: #383838;
        font-weight: bold;
      }
    }
    .refresh {
      color: red;
      font-size: 13px;
    }
    .icon {
      font-size: 60px;
    }
  }
  .tip {
    color: #383838;
    padding: 0 20px 10px 20px;
    font-size: 14px;
    text-indent: 2em;
    span {
      font-size: 18px;
      font-weight: 700;
    }
  }
  .content {
    margin: 10px 0;
  }
  .msg {
    margin: 20px 25px 0;
    padding-left: 30px;
    position: relative;
    font-size: 12px;
    color: #3390ff;
    .icon {
      font-size: 20px;
      position: absolute;
      top: -2px;
      left: 0;
    }
  }
  .footer {
    text-align: center;
    padding: 40px 10px 20px 10px;
  }
}
</style>