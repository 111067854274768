<template>
  <mt-popup v-model="popupVisible" class="getCode" :closeOnClickModal="false">
    <div class="box">
      <img class="close" src="../assets/close2.png" @click="popupVisible = false">
      <div class="content">
        <div class="title">
          <img src="../assets/get_code.png">
          <div>
            {{ $t('getCodeTitle') }}
            <span v-if="phone" class="phone">{{ phone }}</span>
            <span v-else class="phone">{{ user.phone }}</span>
            <div v-if="time" class="time">{{ time }}</div>
          </div>
        </div>
        <div class="code">
          <code-input v-model="code" />
        </div>
        <div class="info">
          {{ $t('codeInfo') }}
        </div>
        <div class="item" :class="{ disable: !!time }" @click="getIvrCode(type)">
          <img src="../assets/ivr.png">
          <span>{{ $t('getIvrHint') }}</span>
        </div>
        <div class="item" :class="{ disable: !!time }" @click="getSmsCode(type)">
          <img src="../assets/code.png">
          <span>{{ $t('getCodeHint') }}</span>
        </div>
      </div>
    </div>
  </mt-popup>
</template>

<script>
import { mapState } from "vuex";
import codeInput from './codeInput'
import { validationCode } from '../utils/api'
export default {
  name: 'GetCode',
  components: {
    codeInput,
  },
  props: {
    phone: String,
    type: String,
  },
  computed: {
    ...mapState(["user", "brand"]),
  },
  data () {
    return {
      popupVisible: false,
      isValidateCode: 'Y',
      time: 0,
      timer: null,
      code: '',
    }
  },
  watch: {
    code(val) {
      if (val.length == 4) {
        this.submit()
      }
    },
    popupVisible(val) {
      if (val) {
        this.getSmsCode(this.type)
      } 
    }
  },
  created() {
    
  },
  methods: {
    popup(val) {
      this.popupVisible = val
    },
    submit() {
      this.$emit('submit', {
        isValidateCode: this.isValidateCode,
        validateCode: this.code,
      })
    },
    async getSmsCode(type) {
      let phone = this.phone || this.user.phone
      if (!phone) {
        return this.$t("phoneError");
      }
      this.$toast(this.$t('getSmsTips'));
      if (this.time) return
      this.time = this.brand.smsWaitInterval || 60
      this.$axios
        .get(validationCode, {
          params: {
            phone: phone.replace(/\s/g, ''),
            type: type
          }
        })
        .then((e) => {
          if (e.status.code == "000") {
            this.timer = setInterval(() => {
              this.time--
              if (this.time <= 0) {
                this.time = 0
                if (e.body.validateSkip == 'Y') {
                  this.isValidateCode = 'N'
                  this.submit()
                }
                clearInterval(this.timer)
              }
            }, 1000)
          } else {
            this.time = 0
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {
          this.time = 0
        })
    },
    getIvrCode(type) {
      let phone = this.phone || this.user.phone
      if (!phone) {
        return this.$t("phoneError");
      }
      this.$toast(this.$t('getSmsTips'));
      if (this.time) return
      this.time = this.brand.smsWaitInterval || 60
      this.$axios
        .get(validationCode, {
          params: {
            phone: phone.replace(/\s/g, ''),
            type: type,
            sendChannel: 'IVR'
          }
        })
        .then((e) => {
          if (e.status.code == "000") {
            this.timer = setInterval(() => {
              this.time--
              if (this.time <= 0) {
                this.time = 0
                if (e.body.validateSkip == 'Y') {
                  this.isValidateCode = 'N'
                  this.submit()
                }
                clearInterval(this.timer)
              }
            }, 1000)
          } else {
            this.time = 0
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {
          this.time = 0
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.getCode {
  background: transparent;
  .box {
    width: 260px;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    height: 60vh;
    min-height: 200px;
    overflow: auto;
    .close {
      position: absolute;
      width: 20px;
      top: 10px;
      right: 10px;
    }
    .content {
      padding-top: 30px;
      .title {
        display: flex;
        font-size: 15px;
        img {
          height: 30px;
          margin-right: 10px;
        }
        .phone {
          color: #FFDB01;
          font-weight: 600;
        }
        .time {
          display: inline-block;
          padding: 2px 10px;
          background-color: #c1c5c8;
          color: white;
          border-radius: 5px;
          margin-left: 5px;
        }
      }
      .code {
        padding: 20px 0px;
      }
      .info {
        padding: 0px 10px 10px 10px;
        font-size: 12px;
        color: #000;
      }
      .item {
        padding: 0px 10px 5px 10px;
        font-size: 14px;
        color: #650188;
        font-weight: 600;
        &.disable {
          color: #adadad;
        }
        img {
          width: 17px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>